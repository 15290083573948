<template>
  <div class="d-flex flex-column flex-root" style="height: 100%">
    <b-alert
        :show="alert.show"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 100001"
        dismissible
        fade
        :variant="alert.color"
        @dismiss-count-down="countDownChanged"
        @dismissed="alert.show === 0"
    >
      {{ alert.message }}
    </b-alert>
    <div
      class="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex justify-content-center flex-row-fluid bgi-no-repeat bgi-position-y-center bgi-position-x-center bgi-size-cover"
        :style="{ backgroundImage: `url(${backgroundImage})` }"
      >
        <!--begin: Aside Container -->
        <div class="d-flex flex-row-fluid flex-column">
          <!--begin: Aside header -->
          <a href="#" class="flex-column-auto d-flex logo">
            <img src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/svg/logos/logo_login.svg" class="h-25 h-lg-100" />
          </a>
          <!--end: Aside header -->
        </div>
        <!--end: Aside Container -->
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden flex-stretch">
        <div class="d-flex flex-column-fluid flex-center mt-10 mt-lg-0">
          <router-view></router-view>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-2.scss";
</style>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "auth",
  methods: {
    countDownChanged (countDown) {
      this.alert.show = countDown
    },
  },
  computed: {
    ...mapGetters([
      "alert"
    ]),

    backgroundImage() {
      return "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/bg/bg_login@3x.png";
    }
  }
};
</script>
